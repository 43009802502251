import React from "react";
import './index.css';
import { Navbar } from "../../components/navbar";
import { Footer } from "../../components/footer";
import { SectionTwo } from "./blocks/section-two";
import { SectionThree } from "./blocks/section-three";
import { SectionFour } from "./blocks/section-four";
import { SectionFive } from "./blocks/section-five";
import { SectionSix } from "./blocks/section-six";
import { SectionSeven } from "./blocks/section-seven";
import { SectionEight } from "./blocks/section-eight";

export const Homepage = () => {
  return (
    <>
      <div className="section-one">
        <Navbar />

          <div className='row section-one__jumpbo'>
              <div className='col-sm-12 col-md-4 section-one__pre-school'>
                  <p>"Providing the best Preschool and Primary<br />Education for your child."</p>
              </div>

              <div className='col-sm-12 col-md-3'></div>

              <div className='col-sm-12 col-md-4 section-one__form-container'>
                  <div className='section-one__form-container__form'>
                      <p>For Enquiries</p>
                      <p>Enter your details below and we will call you shortly</p>

                      <div className='section-one__divider' />
                       <form>
                           <input type='text' className='section-one__form-container__form-input' placeholder={'Your Name'} />
                           <input type='text' className='section-one__form-container__form-input' placeholder={'Your Email'} />
                           <input type='text' className='section-one__form-container__form-input' placeholder={'Your Phone Number'} />
                           <input type='submit' className='section-one__form-container__form-submit' />

                       </form>
                  </div>
              </div>
              <div></div>
          </div>
      </div>

      <SectionTwo />

      <SectionThree />

      <SectionFour />

      <SectionFive />

      <SectionSix />

      <SectionSeven />

      <SectionEight />

      <Footer />
    </>
  );
}
