import React from 'react'
import './index.css';


export const SectionTwo = () => {
    return (
      <div className='section-two__container'>
          <div className='container'>
              <div className='row'>
                  <div className='col-sm-12 col-md-8'>
                      <p className='section-two__header'><span className='lemon'>Who</span> We Are</p>
                      <p>
                          Dear Esteemed parents,<br/>
                          We are glad that you have made the decision to entrust your little one(s) into our care. Here are a few things you need to know about us.
                      </p>
                      <p className='section-two__sub-header'>LIVE STREAMING</p>
                      <p>We stream our activities live because we understand that parents would rather be involved in the day to day activities of their children. We encourage you to watch your little ones on any of your devices while our specially trained faciltators and child minders facilitate their learning and care for them.</p>
                  </div>
                  <div className='col-sm-12 col-md-4 section-two__image'>
                    <img src={'https://res.cloudinary.com/dcq9wirxq/image/upload/v1693524589/happyhearts/aboutbk2_ohxirg.png'} width='276px' height='253px' />
                  </div>
              </div>
          </div>
      </div>
    );
}
