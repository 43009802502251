import './index.css';

const data = [
  {
    title: 'ADVENTURERS',
    description: 'We lay a strong foundation for your child, through interactive classes and activities'
  },
  {
    title: 'DISCOVERERS',
    description: 'We improve your child’s cognitive \n' +
      'thinking and language by keeping \n' +
      'their minds active.'
  },
  {
    title: 'INVENTORS',
    description: 'We create in your child a sense of \n' +
      'belonging, while exploring the\n' +
      'science and technology space.'
  },
  {
    title: 'PRIMARY LEVEL',
    description: 'We use a fusion of the Nigerian \n' +
      'and British curriculum with EYFS/\n' +
      'Montessori approach to teach.'
  },
];

export const SectionThree = () => {
  return (
    <div className="section-three container">
      {
        data.map((card, key) => (
          <div className="col-md-12 col-lg-3" key={key}>
            <div className={`section-three__card ${key == 0 ? 'first' : ''}`}>
              <p className='section-three__card-title'>{card.title}</p>
              <p className='section-three__card-details'>{card.description}</p>
              <input className={`section-three__card-button ${key == 0 && 'first-button'}`} type='button' value={`Learn More${ key == 0 ? '  >' : ''}`} />
            </div>
          </div>
        ))
      }
    </div>
  );
}
