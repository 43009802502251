import React from 'react';
import './index.css';


export const SectionSeven = () => {
    return (
        <div className='classes__section-three__container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <span className='font-header bold font-green'>INVENTORS (4 to 5 years)</span><br/>
                        Our inventors’ classroom is all about the excitement of learning. Children at this age want to be part of the
                        “community of learners” who can read and write, add and subtract, appreciate and explore science and technology,
                        and discover cultural and family diversities. Building on this excitement, our curriculum is selected which enables
                        children to love learning while creating a foundation that will continue to serve them throughout the rest of their
                        lives!<br/><br/>
                        The children interact with the teacher in whole-group and small-group settings. Literature, songs, read-along tapes,
                        games, poems, art posters, maps, pre-decodable and decodable books, and take-home packets are just a few of the
                        ways children experience print awareness.
                    </div>
                </div>
            </div>
        </div>
    );
}
