import React from 'react';
import './index.css';


export const SectionOne = () => {
    return (
        <div className='section-one__container'>
            <div className='row section-one__back1'>
                <h4 className='section-one__header'>ABOUT HAPPY HEARTS</h4>
            </div>

            <div className='container'>
                <div className='row section-one__card-container'>
                    <div className='col-sm-12 col-md-3 section-one__card'>
                        <i className="fa-solid fa-eye fa-2xl"></i>
                        <p className='section-one__card-header'><span className='lemon'>OUR</span> VISION</p>
                        <p className='section-one__card-sub-header'>To raise a generation of diplomats who will stand out everywhere they find themselves positively influencing their world by setting new standards.</p>
                    </div>

                    <div className='col-sm-12 col-md-3 section-one__card'>
                        <i className="fa-solid fa-crosshairs fa-2xl"></i>
                        <p className='section-one__card-header'><span className='lemon'>OUR</span> MISSION</p>
                        <p className='section-one__card-sub-header'>To discover, develop and deploy the potential in every child by creating a refinery that churns out first class diplomats.</p>
                    </div>

                    <div className='col-sm-12 col-md-3 section-one__card'>
                        <i className="fa-solid fa-bars fa-2xl"></i>
                        <p className='section-one__card-header'><span className='lemon'>CORE</span> VALUES</p>
                        <ol className='section-one__card-list'>
                            <li>Responsibility</li>
                            <li>Accountability</li>
                            <li>Innovation</li>
                            <li>Service</li>
                            <li>Excellence</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}
