import React from 'react';
import { Navbar } from "../../components/navbar";
import { Footer } from "../../components/footer";
import { SectionOne } from "./blocks/section-one";
import { SectionTwo } from "./blocks/section-two";
import { SectionThree } from "./blocks/section-three";
import { SectionFour } from "./blocks/section-four";
import { SectionFive } from "./blocks/section-five";
import { SectionSix } from "./blocks/section-six";
import { SectionSeven } from "./blocks/section-seven";
import { SectionEight } from "./blocks/section-eight";
import './index.css';


export const Classes = () => {
    return (
        <>
            <Navbar />

            <SectionOne />

            <SectionTwo />

            <SectionThree />

            <SectionFour />

            <SectionFive />

            <SectionSix />

            <SectionSeven />

            <SectionEight />

            <Footer />
        </>
    );
}
