import React from "react";
import './index.css';

export const SectionEight = () => {
  return (
    <div className='section-eight__container'>
      <div className='container'>
        <div className='row section-eight__body'>
          <div className='col-md-12 col-lg-4 section-eight__join'>
            <p className='text-center section-eight__title'>Join Our New Session</p>
            <p className='text-center section-eight__sub-title'>Call To Enrol Your Child</p>
            <p className='text-center'>
              <span className='section-eight__phone1'>09050505353</span>
              <span className='section-eight__phone2'>09050505656</span>
            </p>
          </div>
          <div className='col-md-12 col-lg-4'></div>
          <div className='col-md-12 col-lg-4'>
            <img alt='' className='section-eight__image' src={'https://res.cloudinary.com/dcq9wirxq/image/upload/v1693410418/happyhearts/kids1_tkrsyx.png'} />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
