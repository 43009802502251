import './index.css';

const data = [
  {
    title: 'Happy Environment',
    description: 'We lay a strong foundation for \n' +
      'your child, through interactive \n' +
      'classes and activities.',
    iconSrc: 'https://res.cloudinary.com/dcq9wirxq/image/upload/v1693407454/happyhearts/environment_icon_dmgdj1.png'
  },
  {
    title: 'Experienced Facilitators',
    description: 'Experienced and friendly staff at your \n' +
      'grasp, to attend to each child’s needs\n' +
      'and care.',
    iconSrc: 'https://res.cloudinary.com/dcq9wirxq/image/upload/v1693407454/happyhearts/experience_icon_kebrur.png'
  },
  {
    title: 'Montessori Method',
    description: 'Hands on, standardized curriculum \n' +
      'and enrichment programs suitable for \n' +
      'your child’s growth.  ',
    iconSrc: 'https://res.cloudinary.com/dcq9wirxq/image/upload/v1693407454/happyhearts/montessori_icon_wlkufy.png'
  },
]

export const SectionFour = () => {
  return (
    <div className='container section-four'>
      <p className='section-four__header'>WHY CHOOSE US</p>

      <div className='section-four__cards'>
        <div className='row'>
          {
            data.map((card, key) => (
              <div className='col-md-12 col-lg-4' key={key}>
                <div className='section-four__card'>
                  <div className='section-four__card-image'>
                    <img src={card.iconSrc} width='72px' height='72px' alt={card.title} />
                  </div>
                  <div>
                    <p>{card.title}</p>
                    <p>{card.description}</p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
