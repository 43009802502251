import React from "react";
import './index.css';

export const SectionTwo = () => {
  return (
    <div className="section-two container">
      <p className='section-two__welcome'>Welcome to Happy Hearts School</p>
      <p className='section-two__details'>
        We provide the right foundation and balance for our pupils through creative and adventurous learning activities, with the use of 21st-century skills and
        active parental involvement, nurturing lifelong learners, equipping them with cognitive, emotional, and physical abilities to become responsible productive,
        and accountable individuals.
      </p>
      <button className='section-two__button'>
        Enrol Now
        <i className="fa-thin fa-greater-than icon"></i>
      </button>
    </div>
  )
}
