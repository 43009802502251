import React from 'react';
import { Cards } from "../../../homepage/blocks/section-five/cards";
import './index.css';


export const SectionFour = () => {
    return (
        <div className='facilities__section-four'>
            <div className='container'>
                <p>
                    <span className='bold font-purple'>EXTRACURRICULAR ACTIVITIES/ENTREPRENEURSHIP CLUBS</span><br/>
                    At HHS we offer a variety of activities and clubs to keep the children meaningfully occupied even as we go a step
                    further to build life skills.<br/><br/>
                    These include: Swimming, Ballet, Taekwondo, Orchestra (Music), Diction, ICT/Coding,<br/>
                    Homemakers/Cookery, Arts and Craft, Science and Book club,
                </p>
                <Cards />
            </div>
        </div>
    );
}
