import React from 'react';
import { Navbar } from "../../components/navbar";
import { Footer } from "../../components/footer";
import { SectionOne } from "./blocks/section-one";
import { SectionTwo } from "./blocks/section-two";
import { SectionThree } from "./blocks/section-three";


export const AboutUs = () => {
    return (
      <div>
          <Navbar />

          <SectionOne />

          <SectionTwo />

          <SectionThree />

          <Footer />
      </div>
    );
}
