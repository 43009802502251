import React from 'react';
import { Navbar } from "../../components/navbar";
import { SectionOne } from './blocks/section-one';
import { SectionThree } from "../homepage/blocks/section-three";
import { SectionFour } from '../homepage/blocks/section-four';
import { SectionSix } from "../homepage/blocks/section-six";
import { Footer } from "../../components/footer";
import './index.css';


export const Admission = () => {
    return (
        <>
            <Navbar />

            <SectionOne />

            <SectionThree />

            <SectionFour />

            <SectionSix />

            <Footer />
        </>
    )
}
