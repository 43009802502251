import React from 'react';


export const Cards = () => {
    return (
        <>
            <div className='section-five__cards'>

                <div className='col-md-12 col-lg-6'>
                    <div className='section-five__card'>
                        <div className='section-five__card__image baby1' />
                        <div className='section-five__card-body'>
                            <div>
                                <p className='section-five__card-body__title'>Kids<br/>Play Land</p>
                            </div>
                            <div>
                                <p className='section-five__card-body__body'>
                                    What’s learning without playing?
                                    Our children get to be team players
                                    and they incorporate the spirit of
                                    teamwork.
                                </p>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>

                <div className='col-md-12 col-lg-6'>
                    <div className='section-five__card'>
                        <div className='section-five__card__image baby2' />
                        <div className='section-five__card-body'>
                            <div>
                                <p className='section-five__card-body__title'>Art<br/>Classes</p>
                            </div>
                            <div>
                                <p>
                                    Join any Art class of your choice.
                                    We have a lot of fun activities for
                                    your child.
                                </p>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='section-five__cards'>

                <div className='col-md-12 col-lg-6'>
                    <div className='section-five__card'>
                        <div className='section-five__card__image baby3' />
                        <div className='section-five__card-body'>
                            <div>
                                <p className='section-five__card-body__title'>School<br/>Programs</p>
                            </div>
                            <div>
                                <p>
                                    They learn to make friends, listen to
                                    teachers, and work through
                                    problems.
                                    Learning these social skills early on
                                    gives kids an important foundation
                                    for their interactions in society.
                                </p>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>

                <div className='col-md-12 col-lg-6'>
                    <div className='section-five__card'>
                        <div className='section-five__card__image baby4' />
                        <div className='section-five__card-body'>
                            <div>
                                <p className='section-five__card-body__title'>Afterschool<br/>Activities</p>
                            </div>
                            <div>
                                <p>
                                    These activities are important for
                                    children, because they help build
                                    endurance, develop strong muscles
                                    and bones, improve fine motor
                                    skills including balance, dexterity,
                                    and flexibility.
                                </p>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
