import React from "react";
import './index.css';


export const SectionOne = () => {
    return (
        <>
            <div className='row classes__section-one-back1'>
                <h4 className='classess__section-one__header'>CLASSES</h4>
            </div>
        </>
    );
}
