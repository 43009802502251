import LOGO from '../../images/happyheartslogo.png';
import { Link, useLocation } from "react-router-dom";
import './index.css';

export const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="navbar navbar-expand-lg navbar-light dark-background">
      <Link className="navbar-brand" to="/">
        <img src={LOGO} height="50px" />
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          <li className={`nav-item ${location.pathname == '/' && 'active'}`}>
            <Link className="nav-link" aria-current="page" to="/">Home</Link>
          </li>
          <li className={`nav-item ${location.pathname == '/about-us' && 'active'}`}>
            <Link className="nav-link" to="/about-us">About Us</Link>
          </li>
          <li className={`nav-item ${location.pathname == '/admission' && 'active'}`}>
            <Link className="nav-link" to="/admission">Admission</Link>
          </li>
          <li className={`nav-item ${location.pathname == '/curriculum' && 'active'}`}>
            <Link className="nav-link" to="/curriculum">Curriculum</Link>
          </li>
          <li className={`nav-item ${location.pathname == '/facilities' && 'active'}`}>
            <Link className="nav-link" to="/facilities">Facilities</Link>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">School Blog</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Gallery</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Contact</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Enrol Now</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}
