import React from 'react';
import './index.css';


export const SectionOne = () => {
    return (
        <div className='row facilities__section-one'>
            <h4 className='facilities__section-one__header'>FACILITIES</h4>
        </div>
    );
}
