import React from 'react';
import './index.css';


export const SectionThree = () => {
    return (
        <div className='container section-three_container'>
            <h5 className='section-three__header'>Our School</h5>

            <p>
                <span className='red bold'>CLASSROOMS:</span> We provide a bright and spacious environment in which your little
                ones can explore the world around
                them using a variety of age-appropriate toys and
                equipment. At the preschool level, most of our classrooms are well
                equipped to aid
                interactive learning time.
            </p>

            <p className='space-top'>
                <span className='bold'>We offer:</span>
                <ol className='list-style'>
                    <li>A clean, spacious, colourful and fun environment.</li>
                    <li>An excellent staff to child ratio to ensure your child gets all the special
                        attention and care they require at this age.</li>
                    <li>Lots of fun interactive playtime.</li>
                </ol>
            </p>

            <p className='space-top'>
                <span className='purple bold'>Preschool/Toddlers</span>
                <ol className='list-style'>
                    <li>A daily report with details of meals, naps and nappy changes as may be
                        necessary so that you can keep in touch
                        with your child’s routine.</li>
                    <li>A well-equipped nursery where your baby can nap.</li>
                </ol>
                In the older preschool classes, we have 10 children in a class and we have a lead facilitator, and a child minder to 10
                children per class. This ratio makes for an ideal
                personalized learning environment.
            </p>

            <p className='space-top'>
                <span className='red bold'>Primary Level</span>
                <ol className='list-style'>
                    <li>Hands on Learning</li>
                    <li>Standardized curriculum and enrichment programs</li>
                    <li>Stimulating environment for learning</li>
                    <li>Professional and friendly facilitators</li>
                </ol>

                Our primary school runs a fusion of the Nigerian and British curriculum make
                learning hands-on and fun. They are
                taught to think for themselves with the
                facilitators as mere guides.
            </p>
        </div>
    )
}
