import React from "react";
import './index.css';


export const SectionThree = () => {
    return (
        <div className='classes__section-three__container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <span className='bold font-red font-header'>EXPLORERS (3 months – 1 year)</span><br/>
                        In the Early Explorers class, we provide a bright and spacious environment in which your little ones can explore the
                        world around them using a variety of toys and equipment. Our dedicated child minders will give your children the
                        individual care, interaction and attention they need at this early stage. <br/><br/><br/>

                        <span className='bold font-header font-blue'>WE OFFER:</span><br/>
                        <ul className='classes__section-three__list'>
                            <li>A clean, spacious, colourful and fun environment.</li>
                            <li>An excellent staff-to-baby ratio to ensure your baby gets all the special attention and care they require at this age.</li>
                            <li>Lots of fun interactive playtime.</li>
                            <li>A daily report with details of meals, naps and nappy changes so you can keep in touch with your baby’s routine.</li>
                            <li>A well-equipped nursery where your baby can nap</li>
                            <li>High chairs for feeding</li>
                        </ul><br/>

                        We try our best to accommodate any special requests you may have for your baby, so please get in touch with us
                        about your requirements and concerns. In the older classes, we have 10 children in a class and we have a lead
                        teacher, an assistant teacher, and a childminder to 10 children per class. This ratio makes for an ideal personalized
                        learning environment. We ensure Potty training is done at 15 months. We do not use the conventional teaching
                        method where teachers teach.<br/>
                        Our facilitators understand that to build tomorrow’s world leaders that will demonstrate self-confidence and
                        responsibility, we need to start the process now so we facilitate learning where pupils and facilitators exchange
                        ideas thereby passing knowledge to each other.
                    </div>
                </div>
            </div>
        </div>
    );
}
