import React from 'react';
import { Cards } from "./cards";
import './index.css';

export const SectionFive = () => {
  return (
    <div className='container'>
      <p className='section-five__title'>SCHOOL PROGRAMS</p>
      <p className='section-five__sub-title'>Here at Happy Hearts, we all feel determined to make your child happy and enriched in both logic and creativity!</p>

      <Cards />
    </div>
  )
}
