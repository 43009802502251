import React from 'react';
import './index.css';


export const SectionFour = () => {
    return(
        <div className='classes_section-four__container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <span className='bold font-purple font-header'>NAVIGATORS (1 – 2 years)</span><br/>
                        We know that TODDLERS learn best when they feel comfortable in a loving environment that gives them the
                        confidence to be themselves while moving around and playing.<br/>

                        The navigators’ space is designed to provide experiences which enhance:
                        <ul className='classes__section-three__list'>
                            <li>Motor development – reaching, crawling in and out, jumping, throwing, and pulling.</li>
                            <li>Cognitive development – object permanence, cause and effect experiences, language, listening, and responding to
                                sounds and voices facilitated by the reading and sharing of picture books.</li>
                            <li>Social development – playing among others, positive peer interactions, and expressing emotions towards others.</li>
                        </ul><br/>

                        Planning for a toddler does not necessarily involve “lessons” but rather opportunities for learning experiences.
                        When our facilitators organize materials for the navigators, they take into account children’s individual differences
                        along with their knowledge of child development. Planning is based on observations and assessments of the
                        children using their interests, their new skills, and their reactions to materials. As the children grow and change, our
                        facilitators change the classroom environment. They may put out more challenging equipment or add different
                        materials to enhance the weekly themes. A class may look very different at the end of the year than it did at the
                        beginning.
                    </div>
                </div>
            </div>
        </div>
    );
}
