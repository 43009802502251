import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './index.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export const SectionSix = () => {
  return (
    <div className='section-six'>
      <div className='container'>
        <p className='section-six__title'>TESTIMONIES</p>
        <p className='section-six__sub-title'>What Our Parents Are Saying...</p>

        <Carousel
          responsive={responsive}
          infinite={true}
        >
          <div className='section-six__testimony'>
            <p className='section-six__testimony-name__testi'>"I took my child to Happy Hearts and I have not had any cause to regret"</p>
            <p className='section-six__testimony-name'>-Mrs Bankole<br/><span className='section-six__testimony-name__title'>Parent</span></p>
          </div>

          <div className='section-six__testimony'>
            <p className='section-six__testimony-name__testi'>"I took my child to Happy Hearts and I have not had any cause to regret"</p>
            <p className='section-six__testimony-name'>-Mrs Bankole<br/><span className='section-six__testimony-name__title'>Parent</span></p>
          </div>

          <div className='section-six__testimony'>
            <p className='section-six__testimony-name__testi'>"I took my child to Happy Hearts and I have not had any cause to regret"</p>
            <p className='section-six__testimony-name'>-Mrs Bankole<br/><span className='section-six__testimony-name__title'>Parent</span></p>
          </div>

          <div className='section-six__testimony'>
            <p className='section-six__testimony-name__testi'>"I took my child to Happy Hearts and I have not had any cause to regret"</p>
            <p className='section-six__testimony-name'>-Mrs Bankole<br/><span className='section-six__testimony-name__title'>Parent</span></p>
          </div>
        </Carousel>
      </div>
    </div>
  )
}
