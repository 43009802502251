import React from 'react';
import { Navbar } from "../../components/navbar";
import { Footer } from "../../components/footer";
import { SectionOne } from "./blocks/section-one";
import { SectionThree as SectionTwo } from "../homepage/blocks/section-three";
import { SectionFour as SectionThree } from "../homepage/blocks/section-four";
import { SectionFour } from "./blocks/section-four";


export const Facilities = () => {
    return(
        <>
            <Navbar />

            <SectionOne />

            <SectionTwo />

            <SectionThree />

            <SectionFour />

            <Footer />
        </>
    )
}
