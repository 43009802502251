import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Homepage } from "./pages/homepage";
import { AboutUs } from "./pages/about-us";
import { NotFound } from "./pages/not-found";
import { Admission } from "./pages/admission";
import { Classes } from "./pages/classes";
import { Facilities } from "./pages/facilities";
// import { Navbar } from "./components/navbar";
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/curriculum" element={<Classes />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="*" element={<NotFound />} />
      </Routes>

    </Router>
  );
}

export default App;
