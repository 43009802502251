import './index.css';

export const SectionSeven = () => {
  return (
    <div className='container section-seven__container'>
      <div className='row section-seven__row'>
        <div className="col-md-12 col-lg-8">
          <p className='section-seven__speech'>
            “I focus on the development of each student, as I am a strong believer that Children are the Leaders of today, not tomorrow. Imbibing a sense of belonging in Children is very crucial and that is why Happy Hearts has come to stay.<br/>
            With our hands-on Montessori Approach, we make sure no Child is left behind. Every Child is a blessing and a gift and each gift differs from one child to the other. At Happy Hearts, we trust in God, our Facilitators and our system, as we uphold values and cover a wide curriculum with varities to impact the young ones.”
          </p>
          <p className='section-seven__hos'>
            Edemanwan Henshaw (Mrs.)<br/>
            Head of School
          </p>
        </div>
        <div className="col-md-12 col-lg-4">
          <img alt='Head of School' className='section-seven__image' src={'https://res.cloudinary.com/dcq9wirxq/image/upload/v1693410260/happyhearts/hos_dfazag.png'} />
        </div>
      </div>
    </div>
  )
}
