import React from "react";
import './index.css';

export const Footer = () => {
  return (
    <div className='footer__container'>
      <div className='container'>
        <div className='row'>
        <div className='col-md-12 col-lg-6 footer__left'>
          <div className='row'>
            <div className='col-sm-12 col-md-6'>
              <img alt='logo' className='footer__left-image' src={'https://res.cloudinary.com/dcq9wirxq/image/upload/v1693411766/happyhearts/logo_xg32ch.png'} />
            </div>
            <div className='col-sm-12 col-md-6'>
              <p>
                Happy Hearts School is a school with great positivity and diverse knowledge. Our school is filled with fun and engaging activities. Come and join us in our educational adventure.
              </p>
            </div>
          </div>
        </div>

        <div className='col-md-12 col-lg-6'>
          <div className='row'>
            <div className='col-sm-12 col-md-4'>
              <p className='footer__header'>Quick Links</p>

              <ul>
                <li>Admissions</li>
                <li>Preschool</li>
                <li>Nursery</li>
                <li>Primary</li>
                <li>Gallery</li>
              </ul>
            </div>

            <div className='col-sm-12 col-md-4'>
              <p className='footer__header'>Be Informed</p>

              <ul>
                <li>School Calendar</li>
                <li>Announcements</li>
                <li>FAQs</li>
              </ul>
            </div>

            <div className='col-sm-12 col-md-4'>
              <p className='footer__header'>Have A Question?</p>

              <ul>
                <li>
                  Happy Hearts School,<br/>
                  23 Oba Akinloye Street,<br/>
                  Oral Estate, Enyo filling station<br/>
                  Off Orchid hotel road,<br/>
                  Ikota-Ajah, Lagos.
                </li>
                <li>
                  09050505353, 09050505656
                </li>
                <li className='footer__connect'>
                  Connect with us<br />
                  <i className="fa-brands fa-square-facebook fa-xl"></i>
                  <i className="fa-brands fa-instagram fa-xl"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='col-sm-12 footer__container-after'/>
      </div>
      </div>
    </div>
  )
}
