import React from 'react';
import './index.css';


export const SectionFive = () => {
    return (
        <div className='classes__section-two__container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <span className='font-red font-header bold'>ADVENTURERS (2 to 3 years)</span><br/>
                        Our Adventurers develop social skills as they learn about their classmates through a variety of activities. They
                        understand what it means to trust and be trusted. They also gain knowledge of ways to get along with others, and
                        understand the importance of being a contributing member of a community group.
                        The play based themed curriculum includes activities which connect literacy, math, science, social studies, and
                        creative arts.<br /><br/>
                        Recognizing that parents have questions regarding potty training, our facilitators and care givers will share their
                        resources with you while emphasizing patience, timing, and consistency in order to ensure that this is a positive
                        experience. They offer the use of the potty at each diaper change, always allow access to the potty, assist parents to
                        implement the child’s wearing of underwear and help the children learn how to change their own clothing.
                    </div>
                </div>
            </div>
        </div>
    );
}
