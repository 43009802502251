import React from 'react';
import './index.css';


export const SectionEight = () => {
    return (
        <div className='classes__section-eight__container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <span className='font-header bold'>PRIMARY LEVEL (5years – 11 years)</span><br/><br/>
                        Our primary school runs a fusion of the Nigerian and British curriculum with EYFS/Montessori undertone to make
                        learning hands-on and fun. The children are taught to think for themselves with the facilitators as mere guides.
                        Subjects offered include- Mathematics (Numeracy), English (Literacy), Science, General Studies, ICT, Creative Arts,
                        Christian Religious Studies & Islamic Religious Studies, French, Music, Vocational Studies, Igbo/Yoruba.<br/><br/>

                        <span className='bold'>At the primary level we boast of the following:</span>
                        <ul className='classes__section-three__list'>
                            <li>Hands-on Learning.</li>
                            <li>Standardized curriculum and enrichment programs.</li>
                            <li>Stimulating environment for learning and Professional and friendly facilitators.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
