import React from 'react';
import './index.css';


export const SectionSix = () => {
    return (
        <div className='classes_section-four__container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <span className='font-wine bold font-header'>DISCOVERERS (3 to 4 years)</span><br/>
                        Here, we focus on cognitive and language, math, science and technology, the arts, social studies and team play.
                        We plan according to the different interests and abilities of the children in the classroom. This approach allows
                        teachers to change and modify the curriculum easily in response to things that happen in the course of the day. An
                        unexpected question can lead to an interesting discussion or an opportunity to have a parent share special skills
                        with the children.
                    </div>
                </div>
            </div>
        </div>
    );
}
