import React from 'react';
import './index.css';


export const SectionTwo = () => {
    return (
        <div className='classes__section-two__container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <span className='bold font-lemon font-header'>PROGRAMMES</span><br/>
                        <span className='bold'>Learning is fun in our school!!!</span><br/>
                        We understand that parents would rather be involved in the day-to-day activities of their children, and that is why
                        here at Happy Hearts, we encourage you to watch your kids by yourself while our trained childminders/facilitators
                        cater for them and facilitate learning as may be the case. Our environment is serene, calm, peaceful and beautiful to
                        aid learning. Our programsstart with Explorers, Navigators, Adventurers, Inventors (Pre-school) and then Primary
                        School (Years 1 – 6).
                    </div>
                </div>
            </div>
        </div>
    );
}
