import React from 'react';
import './index.css';

export const SectionOne = () => {
    return (
        <div className='section-one__container'>
            <div className='row section-one__backadmin'>
                <h4 className='section-one__header'>ADMISSION</h4>
            </div>

            <div className='section_one__two'>
                <div className='container'>
                    <h4 className='section_one__two-header'>REGISTRATION AND ADMISSIONS PROCEDURE</h4>
                    <p>
                        All new pupils are admitted under a performance plan through which their academic progress and behaviour are
                        evaluated. If any child is admitted on trial and at the end of the term the child does not meet up with HHS expected
                        standard, the parents will be invited for a review and Intervention strategies will be worked out for the parent and
                        teacher to follow, in an effort to help the child(ren) meet up with the expected class level.
                        A non-refundable sum of N55,000 applies for our application and registration paid on successful entry test, after
                        which the fee breakdown is given and payment is expected to be made so that the necessary supplies can be given.
                    </p>
                </div>
            </div>
        </div>
    );
}
